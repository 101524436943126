/* Styles for larger screens */
.trending-courses {
  text-align: center;
  margin: 40px auto;
  max-width: 1200px;
}

.course-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  margin-bottom: 30px;
}

.course-card {
  background-color: #c7e1ef;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(46, 142, 177, 0.1);
  height: 100%;
  display: flex;
  flex-direction: column;
}

.course-card img {
  width: 100%;
  max-height: 200px;
  object-fit: cover;
  border-radius: 8px 8px 0 0; /* Rounded corners on top */
  margin-bottom: 10px;
}

.course-card h3 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.course-card p {
  font-size: 1rem;
  margin-bottom: 15px;
}

.course-card a {
  margin-top: auto; /* Push the button to the bottom of the card */
  display: block;
  background-color: #007bff;
  color: #fff;
  text-decoration: none;
  padding: 8px 16px;
  border-radius: 0 0 8px 8px; /* Rounded corners on the bottom */
  transition: background-color 0.3s ease;
}
.course-card a:hover {
  background-color: #0056b3;
}

.footer {
  background-color: #f0f0f0;
  padding: 20px;
  text-align: center;
}

/* Styles for mobile screens */
@media screen and (max-width: 768px) {
  .trending-courses {
    margin: 20px auto; /* Reduce the top margin */
  }

  .course-list {
    grid-template-columns: 1fr; /* Display one column on mobile */
    gap: 10px;
  }

  .course-card {
    height: auto; /* Allow content to dictate card height */
    padding: 10px;
  }

  .course-card img {
    max-height: 150px;
  }

  .course-card h3 {
    font-size: 1.1rem;
  }

  .course-card p {
    font-size: 1rem;
  }
}
