.tab-item {
  list-style-type: none;
}

.tab-button {
  color: black;
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 500;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid #dfe2e5;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 4px;
  background-color: transparent;
  outline: none;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .tab-button {
    font-size: 16px;
    padding-left: 26px;
    padding-right: 26px;
    padding-bottom: 8px;
  }
}

.active {
  color: #ffc107;
  /* border-bottom: 2px solid #ffc107; */
  padding-bottom: 8px;
  color: black;
}

@media screen and (min-width: 768px) {
  .active {
    padding-bottom: 12px;
  }
}
