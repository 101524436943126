.app-container {
  display: flex;
  justify-content: center;
  background-color: transparent;
  min-height: 100vh;
}

.app-store {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 85%;
  max-width: 1110px;
}

.heading {
  color: #1e293b;
  font-family: "Bree Serif";
  font-size: 40px;
  margin-top: 64px;
  margin-bottom: 48px;
}

@media screen and (min-width: 768px) {
  .heading {
    font-size: 48px;
    margin-top: 96px;
  }
}

.search-input-container {
  display: flex;
  align-items: center;
  height: 40px;
  background-color: transparent;
  width: 100%;
  max-width: 250px;
  border-radius: 4px;
  border: 1px solid #7b8794;
  padding-left: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 16px;
}

@media screen and (min-width: 768px) {
  .search-input-container {
    max-width: 480px;
  }
}

.search-input {
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 500;
  border: none;
  outline: none;
  background-color: transparent;
  flex-grow: 1;
}

.search-icon {
  width: 24px;
  height: 24px;
}

.tabs-list {
  display: flex;
  align-items: flex-end;
  padding-left: 0px;
  margin-top: 32px;
}

@media screen and (min-width: 768px) {
  .tabs-list {
    margin-top: 48px;
  }
}

.apps-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  padding-left: 0px;
}
.learm_more_button {
  position: relative;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  padding-block: 0.5rem;
  padding-inline: 1.25rem;
  /* background-color: rgb(0 107 179); */
  background-color: black;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffff;
  gap: 10px;
  font-weight: bold;
  border: 3px solid #ffffff4d;
  outline: none;
  overflow: hidden;
  font-size: 15px;
  cursor: pointer;
}

.learm_more_button:hover {
  transform: scale(1.05);
  border-color: #fff9;
}

.learm_more_button:hover .icon {
  transform: translate(4px);
}

.learm_more_button:hover::before {
  animation: shine 1.5s ease-out infinite;
}

.learm_more_button::before {
  content: "";
  position: absolute;
  width: 100px;
  height: 100%;
  background-image: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0) 70%
  );
  top: 0;
  left: -100px;
  opacity: 0.6;
}

@keyframes shine {
  0% {
    left: -100px;
  }

  60% {
    left: 100%;
  }

  to {
    left: 100%;
  }
}
