.card-container {
    display: flex;
    flex-wrap: wrap;
}

.card {
    width: calc(33.33% - 20px); /* 3 cards per row */
    margin: 10px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
}

.button-container {
    text-align: center;
    margin-top: 10px;
}

.card h3 {
    margin-bottom: 10px;
}
