.scroller {
  max-width: 1000px;
  margin: 20px auto;
}

.scroller__inner {
  padding-block: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.scroller[data-animated="true"] {
  overflow: hidden;
  -webkit-mask: linear-gradient(
    90deg,
    transparent,
    white 0%,
    white 100%,
    transparent
  );
  mask: linear-gradient(90deg, transparent, white 0%, white 100%, transparent);
}

.scroller[data-animated="true"] .scroller__inner {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  flex-wrap: nowrap;
  -webkit-animation: scroll var(--_animation-duration, 40s)
    var(--_animation-direction, forwards) linear infinite;
  animation: scroll var(--_animation-duration, 40s)
    var(--_animation-direction, forwards) linear infinite;
}

.scroller[data-direction="right"] {
  --_animation-direction: reverse;
}

.scroller[data-direction="left"] {
  --_animation-direction: forwards;
}

.scroller[data-speed="fast"] {
  --_animation-duration: 20s;
}

.scroller[data-speed="slow"] {
  --_animation-duration: 60s;
}

@-webkit-keyframes scroll {
  to {
    transform: translate(calc(-50% - 0.5rem));
  }
}

@keyframes scroll {
  to {
    transform: translate(calc(-50% - 0.5rem));
  }
}

/* general styles */

:root {
  --clr-neutral-100: hsl(0, 0%, 100%);
  --clr-primary-100: hsl(205, 15%, 58%);
  --clr-primary-400: hsl(215, 25%, 27%);
  --clr-primary-800: hsl(217, 33%, 17%);
  --clr-primary-900: hsl(218, 33%, 9%);
}

/* html {
  color-scheme: dark;
} */

body {
  min-block-size: 100vh;
  place-content: center;
  font-family: system-ui;
  font-size: 1.125rem;
  background-color: white;
  background-color: aliceblue;
}

.tag-list {
  margin: 0 auto;
  padding-inline: 0;
  list-style: none;
}

.tag-list li {
  padding: 1rem;
  background: hsl(215deg 100% 98.66%);
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem -0.25rem hsl(0deg 0% 84.41%);
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 350px;
}

/* for testing purposed to ensure the animation lined up correctly */
.test {
  background: red !important;
}

.feed_back_item {
  position: relative;
  display: flex;
  align-items: center;
  padding: 1rem;
  background: hsl(215deg 100% 98.66%);
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem -0.25rem hsl(0deg 0% 84.41%);
  overflow: hidden;
  width: 350px;
}

.feed_back_image {
  border-radius: 50%;
  height: 99px;
  width: 99px;
  margin-right: 10px;
}
