.testimonial-item {
  border: 1px solid var(--bs-primary);
  padding: 20px 20px;
}

.testimonial-carousel .owl-item img {
  width: 100px;
  height: 100px;
}

.testimonial-carousel.owl-rtl .testimonial-item {
  direction: ltr !important;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: transparent;
}

@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.logos {
  overflow: hidden;
  padding: 60px 0;
  background: white;
  white-space: nowrap;
  position: relative;
}

.logos:before,
.logos:after {
  position: absolute;
  top: 0;
  width: 250px;
  height: 100%;
  content: "";
  z-index: 2;
}

.logos:before {
  left: 0;
  background: linear-gradient(to left, rgba(255, 255, 255, 0), white);
}

.logos:after {
  right: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0), white);
}

.logos:hover .logos-slide {
  animation-play-state: paused;
}

.logos-slide {
  display: flex;
  animation: 35s slide infinite linear;
}

.logos-slide img {
  height: 50px;
  margin: 0 40px;
}

.logo_container {
  border-radius: 10px;
  border: 1px solid #000;
  background: #f9f9f9;
  height: 100px;
  width: 320px;
  margin-right: 30px;
}

.logo_image {
  border-radius: 50%;
  height: 40px;
  width: 40px;
}

/* Slider zoom */
.zoom_container {
  height: 500px;
  width: 100%;
  overflow: hidden;
  text-align: center;
  animation: sliderZoomEffect 12s linear infinite;
  /* background-color: url("https://img.freepik.com/free-photo/african-woman-teaching-children-class_23-2148892563.jpg?t=st=1701390835~exp=1701391435~hmac=0da2d49ab46c460b09ba3c24cf5b0940954d31962158a8294db88ccd86dc8bcd"); */
}
.image {
  height: 100%;
  width: 100%;
  /* background-image: url("https://media.istockphoto.com/id/1394894776/photo/mechanical-project-blueprint.jpg?s=612x612&w=0&k=20&c=qjKrT1Va2dVhRPscwHAy-ua_tnpq09LJkhhiMu78XAk="); */

  background-position: center;
  transform: 0.7s;
  animation: sliderZoomEffect 12s linear infinite;
}
@keyframes sliderZoomEffect {
  0% {
    transform: scale(1.5);
    box-shadow: none;
  }
  50% {
    transform: scale(1.1);
    box-shadow: none;
  }
  100% {
    transform: scale(1.5);
    box-shadow: none;
  }
}

.bg_heading {
  font-size: 20px;
  color: blue;
}

.service {
  margin-top: 1;
}

/* Login */
/* body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
}*/

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.login-box {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

h2 {
  color: #333;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-group {
  margin-bottom: 20px;
  text-align: left;
  color: #000;
}

label {
  display: block;
  margin-bottom: 8px;
  color: #333;
}

input {
  width: 400px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
}
textarea {
  width: 300px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
}

button {
  background-color: #ffc107;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #ffc107;
}

/* testimonial */

.green {
  color: green;
  size: 300px;
}

/* contact */
.contact {
  align-items: center;
  height: 100%;
  background-image: url("../public/img/contact.jpg");
}

/* headerbutton */
.headerbtn {
  color: #000;
  border-color: #000;
}

.dark-bar {
  width: 3px; /* Adjust the width of the dark bar as needed */
  height: 30px; /* Adjust the height of the dark bar as needed */
  background-color: #000; /* Dark color for the bar */
  /* Adjust the margin as needed to control the space around the bar */
}

/* navbar */
.home {
  margin-left: auto;
}

/* enquiry */
.input {
  width: 400px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* feedback */

/* .txt {
  color: #fff;
} 

/* header */
/* .vertical-bar {
  display: inline-block;
  width: 3px; /* Adjust the width as needed */
/* height: 50px; /* Adjust the height as needed */
/* background-color: black; Dark color for the vertical bar */
/* margin: 0 10px; Adjust the margin as needed */
/* }  */
.header {
  height: 50px;
}
.top {
  margin-top: 8px;
}
.link-with-bar {
  position: relative;
  margin-top: -20px;
  margin-right: 60px;
  /* Adjust as needed to create space for the bar */
}

.link-with-bar::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 3px;
  height: 50px; /* Adjust as needed */
  background-color: black; /* Dark color for the vertical bar */
  margin-left: 8px; /* Adjust as needed to create space between the link and the bar */
}

/* image */
.image-container {
  display: flex;
}

.image {
  margin-right: 10px; /* Adjust the margin to control the space between images */
}

/* login*/
.form_main {
  margin-top: 30px;
  width: 400px;
  height: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;
  margin-left: 600px;
  background-color: rgb(255, 255, 255);
  padding: 30px 30px 30px 30px;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.062);
  position: relative;
  overflow: hidden;
}

.form_main::before {
  position: absolute;
  content: "";
  width: 300px;
  height: 300px;
  background-color: #ffc107;
  transform: rotate(45deg);
  left: -180px;
  bottom: 30px;
  z-index: 1;
  border-radius: 30px;
  /* box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.082); */
}

.heading {
  font-size: 2em;
  color: #2e2e2e;
  font-weight: 700;
  margin: 5px 0 10px 0;
  z-index: 2;
}

.inputContainer {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.inputIcon {
  position: absolute;
  left: 3px;
}

.inputField {
  width: 100%;
  height: 30px;

  border: none;
  border-bottom: 2px solid rgb(173, 173, 173);
  margin: 10px 0;

  font-size: 0.8em;
  font-weight: 500;
  box-sizing: border-box;
  padding-left: 30px;
  background-color: #fff;
}

.inputField:focus {
  outline: none;
  border-bottom: 2px solid #ffc107;
}

.inputField::placeholder {
  font-size: 1em;
  font-weight: 500;
}

#button {
  z-index: 2;
  position: relative;
  width: 100%;
  border: none;
  background-color: rgb(4, 4, 4);
  height: 30px;
  color: white;
  font-size: 0.8em;
  font-weight: 500;
  letter-spacing: 1px;
  margin: 10px;
  cursor: pointer;
}

#button:hover {
  background-color: #ffc107;
}

.forgotLink {
  z-index: 2;
  font-size: 0.7em;
  font-weight: 500;
  color: rgb(44, 24, 128);
  text-decoration: none;
  padding: 8px 15px;
  border-radius: 20px;
}

* {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
}

/* pages */

.vision-overview {
  text-align: center;
  padding: 50px 0; /* Adjust the padding as needed */
}

.item-container {
  margin-bottom: 20px; /* Adjust the margin between items */
}

.image-container img {
  max-width: 50px; /* Make sure images don't exceed their container */
  height: auto; /* Maintain image aspect ratio */
}

.content-container {
  margin-left: 20px; /* Adjust the margin between image and content */
}

.d-flex .item-container .number-container .number-box {
  border-radius: 3px;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  font-size: 14px;
  position: relative;
  z-index: 1;
  color: #fff;
  width: 24px;
  height: 24px;
  padding-left: 5px;
  padding-top: 2px;
}
.d-flex .item-container .number-container .number-box {
  font-size: 18px;
  width: 34px;
  height: 34px;
  padding-left: 0;
  padding-top: 0;
  text-align: center;
  border-radius: 3px;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
}
.number-box {
  background-color: #4a6fa6;
}
.content-container {
  margin-right: 40px;
}


.whyus{
  margin-left: 160px;
}

.whyus_container{
  display: flex;
  flex-direction: column;
}

.whyus_image{
  min-width: 300px;
  height: auto;
  padding: 15px;
  margin-bottom: 15px;
}


@media screen and (min-width:992px) {
  .myfooter{
    margin-left: 450px;
  }

  .whyus{
    margin-left: 700px;
  }
  
  .whyus_container{
    display: flex;
    flex-direction: row;
  }

  .whyus_image{
    min-width: 500px;
    padding: 20px;
    margin-bottom: 20px;
    margin-left: 80px;
  }

}

@media screen and (max-width: 576px) {
    .form-group{
      margin-bottom: 0px;
    }
    .form_main{
      margin-left: 5px;
    }
}