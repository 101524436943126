@import url("https://fonts.googleapis.com/css2?family=Bree+Serif&family=Caveat:wght@400;700&family=Lobster&family=Monoton&family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Playfair+Display+SC:ital,wght@0,400;0,700;1,700&family=Playfair+Display:ital,wght@0,400;0,700;1,700&family=Roboto:ital,wght@0,400;0,700;1,400;1,700&family=Source+Sans+Pro:ital,wght@0,400;0,700;1,700&family=Work+Sans:ital,wght@0,400;0,700;1,700&display=swap");

/* Default styles for internship container */
.internship_container {
  display: flex;
  flex-direction: column;
  margin-bottom: 35px;
  margin-right: 30px;
  transition: transform 0.5s ease;
}

.internship_container:hover {
  transform: scale(1.1);
}

.internship_container:not(:hover) {
  transform: scale(1);
}

.internship_card_image {
  height: 130px;
  width: 190px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.internship_heading {
  color: orange;
  font-weight: 800;
  font-size: 20px;
  display: block;
}

/* Responsive styles using media queries */
@media only screen and (max-width: 768px) {
  .internship_container {
    margin-right: 0;
  }

  .internship_card_image {
    width: 100%; /* Make the image width 100% of the container */
  }
}

/* Add more media queries for other screen sizes if needed */
