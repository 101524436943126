.app-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style-type: none;
  background-color: transparent;
  border-radius: 12px;
  padding-top: 16px;
  margin-right: 15px;
  margin-bottom: 10px;
  width: 105%;
  max-width: 188px;
  perspective: 1000px;
}

@media screen and (min-width: 768px) {
  .app-item {
    max-width: 180px;
    height: 200px;
    padding-top: 24px;
    margin-left: 10px;
    margin-right: 40px;
    margin-bottom: 20px;
  }
}

.app-image {
  width: 190px;
  height: 190px;
}

@media screen and (min-width: 768px) {
  .app-image {
    width: 189px;
    height: 185px;
    border-radius: 5px;
  }
}

.app-name {
  color: #1e293b;
  font-family: "Bree Serif";
  font-size: 16px;
}

@media screen and (min-width: 768px) {
  .app-name {
    font-family: "Bree Serif";
    font-size: 24px;
  }
}

.card {
  width: 258px;
  height: 204px;
  perspective: 1000px;
  margin-bottom: 30px;
  margin-right: 12px;
  border: 1px solid transparent;
}

.card-inner {
  width: 179px;
  height: 190px;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.9s;
}

.app-item:hover .card-inner {
  transform: rotateY(180deg);
}

.card-front,
.card-back {
  position: absolute;
  width: 179px;
  height: 190px;
  backface-visibility: hidden;
}

.card-front {
  background-color: #ffc107;
  width: 200px;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 10px solid #ffc107;
  border-radius: 10px;
  font-size: 17px;
  transform: rotateY(0deg);
}

.card-back {
  background-color: transparent;
  width: 200px;
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid #ffc107;
  border-radius: 10px;
  font-size: 17px;
  transform: rotateY(180deg);
}

.wcu-card-image {
  width: 230px;
  height: 243px;
  border-radius: 4px;
}

.learm_more_button {
  position: relative;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  padding-block: 0.5rem;
  padding-inline: 1.25rem;
  background-color: rgb(0 107 179);
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffff;
  gap: 10px;
  font-weight: bold;
  border: 3px solid #ffffff4d;
  outline: none;
  overflow: hidden;
  font-size: 15px;
  cursor: pointer;
}

.learm_more_button:hover {
  transform: scale(1.05);
  border-color: #fff9;
}

.learm_more_button:hover .icon {
  transform: translate(4px);
}

.learm_more_button:hover::before {
  animation: shine 1.5s ease-out infinite;
}

.learm_more_button::before {
  content: "";
  position: absolute;
  width: 100px;
  height: 100%;
  background-image: linear-gradient(
    120deg,
    rgba(255, 255, 255, 0) 30%,
    rgba(255, 255, 255, 0.8),
    rgba(255, 255, 255, 0) 70%
  );
  top: 0;
  left: -100px;
  opacity: 0.6;
}

@keyframes shine {
  0% {
    left: -100px;
  }

  60% {
    left: 100%;
  }

  to {
    left: 100%;
  }
}

.description_appitem {
  font-size: 15px;
  text-align: center;
}
