.form-group {
    margin-bottom: 15px;
}

label {
    display: inline-block;
    width: 120px; /* Adjust width as needed */
    margin-right: 10px;
}

input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: calc(100% - 130px); /* Adjust width to fill remaining space */
}

button {
    margin-top: 15px;
    padding: 10px 20px;
    background-color: #007bff;
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #0056b3;
}
