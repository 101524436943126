/* App.css */

.app-container {
  display: flex;
  flex-direction: row;
}

.tabs-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-left: 10px;
}

.tab {
  cursor: pointer;
  padding: 10px;
  margin-bottom: 5px;
  border: 1px solid transparent;
  border-radius: 5px;
  user-select: none;
}

.tab.active {
  background-color: #ffc107;
  color: black;
}

.content-container {
  margin-left: 18px;
  width: 78%;
}

.content {
  padding: 15px;
  border-radius: 5px;
}

.recruiter-card {
  display: flex;
  align-items: center;
}

.recruiter-card img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.placement_icon {
  height: 15px;
  width: 15px;
  margin-right: 10px;
  margin-top: 15px;
}

/* our placement TAB card  css code*/
.ourplacement-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.card {
  width: 240px;
  margin: 15px;
  padding: 10px;
  height: 320px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  display: flex;
  &:hover {
    transform: scale(1.05);
  }
}

.card-image {
  width: 105px;
  height: 105px;
  margin-top: 30px;
  border-radius: 8px;
}

.card-content {
  margin-top: 10px;
  margin-left: 15px;
}

.card-name {
  font-size: 1.2em;
  font-weight: bold;
}

.card-details {
  font-size: 1em;
  color: #666;
}

/* Media Query for smaller screens */
@media only screen and (max-width: 768px) {
  .card {
    width: 100%;
  }
}

.placement_procss_heading {
  font-size: 19px;
  color: orange;
  text-align: center;
}

.Cad_desk_presention_heading {
  font-size: 18px;
}

.state_heading {
  font-size: 18px;
}
/* END OUR PLACEMENT TAB*/

/*OUR RECRUITER */
.our_recruiter-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.recruiter_card {
  width: 200px;
  margin: 15px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}
